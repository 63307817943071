import { template as template_8a446dd3016b4116b48a20dbc1195e3c } from "@ember/template-compiler";
const WelcomeHeader = template_8a446dd3016b4116b48a20dbc1195e3c(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
