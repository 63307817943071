import { template as template_e50631607ed44c23927dec27515ca2b0 } from "@ember/template-compiler";
const FKControlMenuContainer = template_e50631607ed44c23927dec27515ca2b0(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
