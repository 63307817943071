import { template as template_dc9efa4d965c44e9bc8cdf18ef9d08fe } from "@ember/template-compiler";
const SidebarSectionMessage = template_dc9efa4d965c44e9bc8cdf18ef9d08fe(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
